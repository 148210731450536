<template>
  <div class="user-detail">
    <Nav></Nav>
    <div class="detail-con mobile-present">
      <!-- <div class="detail-mobile-title">{{ reporterOne.name }}</div> -->
      <div class="detail-mobile-userImg">
        <img class="mobile_img" :src="reporterOne.imgURL" />
      </div>
      <div class="mobile-user-text">
        <div class="mobile-user-title">艺术简介</div>
        <div>
          <span>{{ reporterOne.name }}</span>
          <span v-html="reporterOne.detail"></span>
        </div>
      </div>
      <div class="mobile-user-pic">
        <div class="pic-show">
          {{ reporterOne.name }}
          <a
            :href="'/userPostMore.html' + '?id=' + reporterOne.id"
            target="_blank"
            >作品赏析>></a
          >
        </div>
        <div class="pic-list" v-for="post in postpicList" :key="post.id">
          <img class="pic-img" :src="post.imageURL" alt="" />
        </div>
      </div>
    </div>
    <div class="detail-con desktop-present">
      <div class="user-title">
        <div class="title-icon"></div>
        <div class="title-text">首页 > 摄影师</div>
      </div>
      <div class="detail-top">
        <div class="detail-txet">
          <div class="detail-img">
            <div
              class="m_img"
              :style="{ backgroundImage: `url(` + reporterOne.imgURL + `)` }"
            ></div>
          </div>
          <div class="detail-comment">
            <div class="name">---- {{ reporterOne.name }} ----</div>
            <div class="en-name">{{ reporterOne.nameEn }}</div>
            <div v-html="reporterOne.detail"></div>
          </div>
        </div>
      </div>
      <div class="detail-post">
        <div class="post-title">
          <div class="name">优秀作品</div>
          <div class="en-name">Excellent Works</div>
          <div class="more">
            <a
              :href="'/userPostMore.html' + '?id=' + reporterOne.id"
              target="_blank"
              >更多>></a
            >
          </div>
        </div>
        <div class="post-list">
          <div class="post-item" v-for="post in postpicList" :key="post.id">
            <div class="item">
              <div
                class="item-img"
                :style="{ backgroundImage: `url(` + post.imageURL + `)` }"
                @click="onLookPost(post.id)"
              ></div>
              <div class="item-info">
                <div class="title">{{ post.title }}</div>
                <div class="name">---- {{ post.author }} ----</div>
                <div class="time">{{ post.eventTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      reporterOne: {}, //记者的信息
      userList_registed: [],
      swiperIndex: 0, // 轮播的index
      transformDistance: 1, // 图片移动的距离
      transformSwiperDistance: 1,
      postpicList: [],
      picText: "",
    };
  },
  created() {
    this.reload();
  },
  methods: {
    async reload() {
      const thiz = this;
      console.log("sjjs", this.$route.query);
      thiz.userList_registed.forEach((k) => {
        if (k.id == this.$route.query.id) {
          thiz.reporterOne = k;
        }
      });
      if (this.$route.query.code) {
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithPic(this.$route.query.code)
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL();
            data.headPostDetails.forEach((k) => {
              k.imageURL = RD.pic().id(k.frontPicId).mImage_URL();
            });
            this.postpicList = data.headPostDetails;
            if (this.postpicList.length != 0) {
              this.picText = this.postpicList[0].text;
            }

            this.reporterOne = data;
          });
      } else {
        console.log("post");
        RD.userShow()
          .id(this.$route.query.id)
          .oneWithoutType()
          .then((data) => {
            data.imgURL = RD.userShow().id(data.id).headPic_URL();
            data.headPostDetails.forEach((k) => {
              k.imageURL = RD.pic().id(k.frontPicId).mImage_URL();
            });
            this.postpicList = data.headPostDetails;
            this.reporterOne = data;
          });
      }
    },
    // 稿件内的小图
    postpic(index) {
      console.log("ss", index);
      console.log("oppp", this.reporterOne.headPostDetails[index].id);
      RD.post()
        .id(this.reporterOne.headPostDetails[index].id)
        .one()
        .then((info) => {
          console.log("ipp", info);
          info.pics.forEach((j) => {
            j.imgURL = RD.pic().id(j.id).mImage_URL();
          });
          this.postpicList = info.pics;
        });
    },
    // 小图的点击
    onClickSwiper(index) {
      var thiz = this;
      thiz.transformDistance = index * 110;
      this.swiperIndex = index;
      this.picText = this.postpicList[index].text;
      // this.postpic(index)
    },
    // 图片左移
    onGoleft() {
      console.log("aa", this.swiperIndex);
      if (this.swiperIndex >= 1) {
        this.transformDistance = this.transformDistance - 110;
        this.swiperIndex = this.swiperIndex - 1;
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1;
      } else {
        this.transformDistance = 0;
        this.swiperIndex = 0;
        this.$message("已经是第一张图片了");
      }
      this.picText = this.postpicList[this.swiperIndex].text;
    },
    // 图片右移
    onGoright() {
      var piclength = this.postpicList.length - 1;
      if (this.swiperIndex == piclength) {
        this.$message("已经是最后一张了");
        this.swiperIndex = piclength;
      } else {
        this.transformDistance = this.transformDistance + 110;
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1;
        this.swiperIndex = this.swiperIndex + 1;
      }
      this.picText = this.postpicList[this.swiperIndex].text;
    },
    // 小图左移5张
    onGoPicLeft() {
      console.log("currIndex", this.swiperIndex);
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0;
        this.$message("已经是第一张了");
      } else if (this.swiperIndex == 2) {
        this.calculate();
      } else if (this.swiperIndex == 1) {
        this.calculate();
      } else if (this.swiperIndex == 3) {
        this.calculate();
      } else if (this.swiperIndex == 4) {
        this.calculate();
      } else if (this.swiperIndex == 5) {
        this.transformDistance = this.transformDistance - 110 * 5;
        this.swiperIndex = 0;
        this.transformSwiperDistance = 0;
      } else if (this.swiperIndex == 6) {
        this.swiperIndex = this.swiperIndex - 2;
        this.transformDistance =
          this.transformDistance - 110 * (this.swiperIndex - 2);
        this.transformSwiperDistance =
          this.transformSwiperDistance - 22.1 * (this.swiperIndex - 2);
      } else if (this.swiperIndex == 7) {
        this.swiperIndex = this.swiperIndex - 3;
        this.transformDistance =
          this.transformDistance - 110 * (this.swiperIndex - 1);
        this.transformSwiperDistance =
          this.transformSwiperDistance - 22.1 * (this.swiperIndex - 1);
      } else if (this.swiperIndex == 8) {
        this.swiperIndex = this.swiperIndex - 4;
        this.transformDistance =
          this.transformDistance - 110 * this.swiperIndex;
        this.transformSwiperDistance =
          this.transformSwiperDistance - 22.1 * this.swiperIndex;
      } else if (this.swiperIndex == 9) {
        this.swiperIndex = this.swiperIndex - 5;
        this.transformDistance =
          this.transformDistance - 110 * (this.swiperIndex + 1);
        this.transformSwiperDistance =
          this.transformSwiperDistance - 22.1 * (this.swiperIndex + 1);
      } else {
        this.swiperIndex = this.swiperIndex - 5;
        this.transformDistance = this.transformDistance - 110 * 5;
        this.transformSwiperDistance = this.transformSwiperDistance - 22.1 * 5;
      }
      this.picText = this.postpicList[this.swiperIndex].text;
      console.log("now", this.swiperIndex);
    },
    // 小图右移5张
    onGoPicRight() {
      var piclength = this.postpicList.length - 1;
      if (this.swiperIndex == piclength) {
        this.$message("已经是最后一张了");
        this.swiperIndex = piclength;
      } else if (this.swiperIndex == piclength - 3) {
        this.calculateright();
      } else if (this.swiperIndex == piclength - 2) {
        this.calculateright();
      } else if (this.swiperIndex == piclength - 1) {
        this.calculateright();
      } else if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 5;
        this.transformDistance =
          this.transformDistance + 110 * this.swiperIndex;
        this.transformSwiperDistance =
          this.transformSwiperDistance + 22.1 * this.swiperIndex;
      } else {
        this.transformDistance = this.transformDistance + 110 * 5;
        this.transformSwiperDistance = this.transformSwiperDistance + 22.1 * 5;
        this.swiperIndex = this.swiperIndex + 5;
      }
      this.picText = this.postpicList[this.swiperIndex].text;
    },
    // 计算距离
    calculate() {
      this.swiperIndex = this.swiperIndex - 1;
      this.transformDistance = this.transformDistance - 110;
      this.transformSwiperDistance = this.transformSwiperDistance - 22.1;
    },
    // 计算距离
    calculateright() {
      this.swiperIndex = this.swiperIndex + 1;
      this.transformDistance = this.transformDistance + 110;
      this.transformSwiperDistance = this.transformSwiperDistance + 22.1;
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-detail {
  width: 100%;
  height: auto;
  .detail-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    .user-title {
      text-align: left;
      display: flex;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      border-bottom: 1px solid #aeaeae;
      .title-icon {
        width: 1.5rem;
        height: 3rem;
        background: no-repeat url(../assets/loc.png);
        background-size: contain;
        margin-top: 13px;
      }
      .title-text {
        margin-left: 20px;
      }
    }
    .detail-top {
      height: auto;
      position: relative;
      background-color: #fff;
      .detail-txet {
        width: 120rem;
        margin: 0 auto;
        .detail-img {
          height: 12rem;
          width: 15rem;
          margin: 0 auto;
          .m_img {
            margin: 0.5rem auto;
            width: 10rem;
            height: 10rem;
            border-radius: 5rem;
            background: no-repeat 50% / cover;
          }
        }
        .detail-comment {
          height: auto;
          width: 60rem;
          margin: 0 auto;
          text-align: left;
          font-size: 1.4rem;
          color: #545454;
          //   text-indent: 2em;
          line-height: 3.6rem;
          padding: 0rem 1rem;
          .name {
            color: #333;
            font-size: 22px;
            text-align: center;
            margin-top: 20px;
          }
          .en-name {
            text-align: center;
            vertical-align: bottom;
            margin-left: 10px;
            color: #b5b5b5;
            font-weight: lighter;
            font-family: "Microsoft Yahei", "Simsun";
          }
        }
      }
    }
    .detail-post {
      height: auto;
      flex-wrap: wrap;
      width: 120rem;
      margin: 4rem auto;
      .post-title {
        height: 48px;
        width: 120rem;
        margin: 0 auto;
        margin-bottom: 20px;
        font-weight: lighter;
        position: relative;
        text-align: left;
        .name {
          font-size: 36px;
        }
        .en-name {
          position: absolute;
          left: 15rem;
          top: 15px;
          vertical-align: bottom;
          margin-left: 10px;
          font-size: 22px;
          color: #b5b5b5;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .more {
          position: absolute;
          right: 0px;
          top: 0px;
          font-size: 18px;
          font-weight: lighter;
          color: #aeaeae;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
      .post-list {
        display: flex;
        gap: 20px;
        overflow: hidden;
        .post-item {
          width: 28rem;
          height: 39rem;
          background: #fff;
          flex-shrink: 0;
          .item {
            .item-img {
              width: 100%;
              height: 27.4rem;
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              cursor: pointer;
            }
            .item-info {
              .title {
                height: 3rem;
                line-height: 30px;
                margin-top: 22px;
                font-size: 18px;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .name {
                text-align: center;
                height: 20px;
                line-height: 20px;
                color: #aeaeae;
                font-size: 12px;
                font-family: "Microsoft Yahei", "Simsun";
              }
              .time {
                text-align: center;
                height: 20px;
                line-height: 20px;
                color: #aeaeae;
                font-size: 12px;
              }
            }
          }
        }
        .post-item:nth-child(7 + n) {
          display: none;
        }
      }
    }
  }
  .detail-con.mobile-present {
    width: 750px;
    .detail-mobile-userImg {
      width: 50%;
      height: 50%;
      border-radius: 189px;
      margin: 0 auto;
      overflow: hidden;
      .mobile_img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / contain;
      }
    }
    .mobile-user-text {
      font-size: 35px;
      margin: 20px;
      letter-spacing: 0.1rem;
      line-height: 80px;
      font-family: Microsoft YaHei;
      text-align: left;
      .mobile-user-title {
        height: 60px;
        line-height: 60px;
      }
      span {
        font-weight: bold;
        margin-right: 20px;
      }
    }
    .mobile-user-pic {
      .pic-show {
        font-size: 35px;
        height: 70px;
        line-height: 70px;
      }
      .pic-list {
        width: 100%;
        margin-bottom: 20px;
        .pic-img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }
      }
    }
  }
}
</style>
